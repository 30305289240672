import orderService from "../../services";

export default {
    data() {
        return {
            hasSuperAdmin: false,
            searchedResultShow: false,
            search: "",
            country_id: (this.$route.query.country ? this.$route.query.country : this.defaultFilterCountryId()),
            orders: [],
            meta: {
                current_page: 1,
            },
            statistics: {},
            currentTab: 'pending'
        }
    },
    mounted() {
        if ('user_type' in this.$store.state.user && this.$store.state.user.user_type == "super_admin") {
            this.hasSuperAdmin = true;
        }
        this.getOrders('pending');
        this.getFilteredOrdersStatistics();
        this.getNextRecord();
    },
    methods: {
        getFilteredOrdersStatistics() {
            this.showLoader(true);
            orderService.getFilteredOrdersStatistics().then(
                (response) => {
                    this.hideLoader();
                    this.statistics = {...response.data}
                }
            )
        },
        getOrders(status) {
            this.showLoader(true);
            this.currentTab = status;
            orderService.getOrders(
                {
                    'status': status,
                    'search': this.search
                }).then(response => {
                    this.hideLoader();
                    this.orders = response.data.data;
                    this.meta.current_page = response.current_page;

                });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    orderService.getOrders({
                        "search": this.search,
                        'page': this.meta.current_page + 1
                    }).then(response => {
                        this.hideLoader();
                        this.orders.push(...response.data.data);
                        this.meta = response.data.meta;
                        this.loading = false;
                    })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getOrders(this.currentTab);
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getOrders(this.currentTab);
            this.searchedResultShow = false;
        },
    }
}