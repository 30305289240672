export default {
    data() {
        return {
            orderId: '',
            orderTitle: ''
        }
    },
    created() {
        this.orderId = this.$route.params.id;
        this.orderTitle = this.$route.query.orderId;
    }
}