import orderService from "../../services";
import userData from "../../../user/services/user-data";
import appConfig from "@/common/services/app-config";
import passbookService from "../../../passbook/services";

export default {
    data() {
        return {
            products: [],
            groups: [],
            comingGroups: [],
            mode: 'prepare',
            profile: {},
            filterInput: "All Products",
            filterOptions: [
                `All Products`,
                'New Launched'
            ],
            recentlyLaunchedProducts: [],
            applyRewards: true,
            rewardsBalance: 0,
            rewardPercentage: 0,
            rewardRatio: 1,
            summary: null,
            itemsCount: 0,
        }
    },
    watch: {
        'orderedProducts': function () {
            if (this.orderedProducts.length < 1) {
                this.mode = 'prepare';
                window.scrollTo(0, 0);
            }
        },
        'filterInput': function () {
            if (this.filterInput == 'All Products') {
                this.itemsCount = this.products.length;
            }
            if (this.filterInput == 'New Launched') {
                this.itemsCount = this.recentlyLaunchedProducts.length;
            }
        }
    },
    computed: {
        rewardsAmount() {
            return Math.floor(this.rewardsBalance / this.rewardRatio);
        },
        availableRewards() {
            return this.summary ? this.summary.reward_redeemed : 0;
        },
        availableRewardsAmount() {
            return this.summary ? this.summary.reward_redeemed_amount : 0;
        },
        orderedProducts() {
            return this.products.filter(p => p.quantity > 0);
        },
        subTotal() {
            return this.summary ? this.summary.sub_total : 0;
        },
        margin() {
            return this.summary ? this.summary.margin : 0;
        },
        afterMargin() {
            return this.subTotal - this.margin;
        },
        tax() {
            return this.summary ? this.summary.tax : 0;
        },
        rewards() {
            return this.applyRewards ? this.availableRewards : 0;
        },
        shipping() {
            return 0;
        },
        adjustment() {
            return this.summary ? this.summary.adjustment : 0;
        },
        total() {
            return this.summary ? this.summary.total : 0;
        },
        rewardEarned() {
            return this.summary ? this.summary.reward_earned : 0;
        }
    },
    mounted: function () {
        this.showLoader(true);
        this.getProducts();
        userData.getProfileDetail().then(response => {
            this.profile = response.data.data;
        });
        orderService.getRewardBalance().then(response => {
            this.rewardsBalance = response.data.amount;
            this.rewardRatio = response.data.reward_amount_ratio;
            this.rewardPercentage = response.data.partner_reward_percentage;
        })
        if ('user' in this.$store.state && this.$store.state.user != null) {
            passbookService.getRewardBalance({
                "user_id": this.$store.state.user.id,
            }).then(response => {
                this.hideLoader();
                this.currentRewardBalance = response.data.reward_amount_ratio;
            })
        }

    },
    methods: {
        groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [ item ]);
                } else {
                    collection.push(item);
                }
            });
            return [ ...map ].map(([ name, value ]) => ({ name, value }));
        },
        setQuantity(product, step) {
            if (step == -1 && product.quantity !== 0) {
                product.quantity = product.quantity + step;
            }
            else if (step == 1) {
                product.quantity = product.quantity + step;
            }
            if(product.quantity != 0){
                this.summerize();
            }
        },
        getProducts() {
            orderService.getProducts().then(response => {
                this.hideLoader();
                const allProducts = response.data.map(p => {
                    p.quantity = 0;
                    p.line_price = p.crm_data.Base_Price * (p.crm_data.Box_Units || 1);
                    p.available_quantity = Math.floor(p.crm_data.Qty_in_Stock / (p.crm_data.Box_Units || 1));
                    return p;
                });

                this.products = allProducts.filter(p => p.crm_data.Product_Active);
                this.itemsCount = this.products.length;
                this.groups = this.groupBy(this.products, function (p) {
                    let parts = p.Product_Code.split('-');
                    parts.pop();
                    return parts.join('-');
                });

                this.recentlyLaunchedProducts = allProducts.filter(p => p.crm_data.Recently_Launched == true);

                this.comingProducts = allProducts.filter(p => !p.crm_data.Product_Active);
                this.comingGroups = this.groupBy(this.comingProducts, function (p) {
                    let parts = p.Product_Code.split('-');
                    parts.pop();
                    return parts.join('-');
                });
            });
        },
        review() {
            this.mode = 'review';
            window.scrollTo(0, 0);
        },
        summerize() {
            this.summary = null;
            let products = this.orderedProducts.map(p => {
                return {
                    id: p.id,
                    quantity: p.quantity
                }
            });
            orderService.summerizeOrder(products).then(response => {
                this.summary = response.data.order;
            })
        },
        checkout() {
            if (this.orderedProducts.length < 1) {
                this.mode = 'prepare';
                window.scrollTo(0, 0);
                return;
            }

            if (this.paymentData) {
                this.payment(this.paymentData);
            }
            else {
                let products = this.orderedProducts.map(p => {
                    return {
                        id: p.id,
                        quantity: p.quantity
                    }
                });
                this.showLoader(true);
                orderService.createOrder(products).then(response => {
                    this.paymentData = response.data;
                    this.payment(response.data);
                    this.hideLoader();
                })
            }
        },
        payment(data) {
            let order = data.payment;
            let lifelyOrder = data.order;
            var options = {
                "key": appConfig.razorpayApiKey.IN, // Enter the Key ID generated from the Dashboard
                "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": order.currency,
                "name": "Lifely",
                "description": `lifely order ${ order.notes.title }`,
                "image": "https://partner.lifely.world/images/lifely/logo-dark.png",
                "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": (response) => {
                    console.log(response);
                    this.$router.push({
                        name: 'order-success',
                        params: { id: lifelyOrder.id },
                        query: { orderId: lifelyOrder.title }
                    });
                },
                "prefill": {
                    "name": order.notes.customer_name,
                    "email": order.notes.customer_email,
                    "contact": order.notes.customer_phone
                },
                "notes": order.notes,
                "theme": {
                    "color": "#2c4c32"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on('payment.failed', function (response) {
                console.log(response.error);
                // let failedNotes = `
                //     code : ${response.error.code}
                //     description : ${response.error.description}
                //     source : ${response.error.source}
                //     step : ${response.error.step}
                //     reason : ${response.error.reason}
                // `;
                // alert(failedNotes);
            });
            rzp1.open();
        }
    }
}