<app-header v-if="mode=='prepare'">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Create Order</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<app-header v-if="mode=='review'" menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Review Order</h1>
    <a href="#" @click="mode='prepare'" class="header-icon header-icon-1">
        <i class="fas fa-arrow-left"></i>
    </a>
</app-header>

<!-- STICKY MAKE ORDER FOR PREPARE PAGE START -->
<div :class="[$store.state.user.user_type != 'super_admin'? 'cartQtyCardForNonSuperAdmin' : 'cartQtyCardForSuperAdmin']"
    v-if="mode == 'prepare'">
    <div class="card mb-0" :class="[ orderedProducts.length > 0 ? 'availableItem' : 'unAvailableItem' ]">
        <div class="content">
            <div class="d-flex justify-content-center align-items-center mb-3">
                <div class="ml-auto itemQtyNumberContainer fontWeight">
                    <h4 class="font-20 mb-0">{{orderedProducts.length}}</h4>
                </div>
                <div class="mr-3">
                    <h4 class="font-20 letterSpacing mb-0 fontWeight ms-2">items</h4>
                </div>
            </div>
            <div class="row mb-1">
                <button @click="review()"
                    class="btn btn-full btn-m text-uppercase font-800 rounded-sm mb-3 letterSpacing primary-light-bg">
                    Review Order Summary
                </button>
            </div>
        </div>
    </div>
</div>

<!-- STICKY MAKE ORDER FOR PREPARE PAGE END -->


<!-- STICKY MAKE PAYMENT FOOTER FOR REVIEW PAGE START -->

<div class="card mb-0"
    :class="[$store.state.user.user_type != 'super_admin'? 'cartTotalCardForNonSuperAdmin' : 'cartTotalCardForSuperAdmin']"
    v-if="mode == 'review'">
    <div class="content">
        <div class="mb-4 totalAmountPanel">
            <div class="text-center">
                <h4 class="font-20 letterSpacing mb-0 fontWeight">Total Amount</h4>
            </div>
            <div class="text-center">
                <h4 class="font-20 letterSpacing text-success fontWeight" v-if="total > 0">
                    {{orderedProducts[0].crm_data.$currency_symbol}}{{total.toFixed(2)}}
                </h4>
                <div class="d-flex justify-content-center align-items-center" v-else>
                    <div class="spinner-border primary-alt-light-text"
                        style="border-width: 2px; width: 1.75rem; height: 1.75rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <a href="#billingSummary">
                <i class="fas fa-chevron-down primary-light-text"></i>
            </a>
        </div>
        <div class="row mb-0">
            <button @click="checkout"
                class="btn btn-full btn-m text-uppercase mb-3 font-800 rounded-sm letterSpacing primary-light-bg">
                Make Payment
            </button>
        </div>
    </div>
</div>

<!-- STICKY MAKE PAYMENT FOOTER FOR REVIEW PAGE END -->

<AppFooter />


<div class="page-title-clear"></div>


<!-- PREPARE ORDER PAGE SECTION START-->

<div class="page-content preparePageContainer" v-if="mode == 'prepare'">

    <div class="content mb-0 mt-1" id="tab-group-1">
        <div class="tab-controls tabs-small tabs-rounded" data-highlight="bg-primary-light-color">
            <a href="#" class="letterSpacing line-height-s px-1 py-2 btn-ripple" data-active data-bs-toggle="collapse"
                data-bs-target="#tab-1ab">
                Available
            </a>
            <a href="#" class="letterSpacing line-height-s px-1 py-2 btn-ripple" data-bs-toggle="collapse"
                data-bs-target="#tab-2ab">
                Launching Soon...
            </a>
        </div>



        <!-- AVAILABLE PRODUCTS LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse show" id="tab-1ab">

            <!-- FILTER BY START -->
            <div class="mt-2">
                <h4 class="font-13 font-500 letterSpacing text-secondary mb-1 ms-2">
                    <i class="fas fa-filter primary-alt-light-text me-2"></i>
                    Filter By
                </h4>
                <form-control class="validate-field input-style-active">
                    <app-select class="border-no custom-dropdown font-14 font-500 letterSpacing" name="Filter By"
                        placeholder="" v-model="filterInput" :options="filterOptions" disableSearchBar="true"
                        disableClearSelection="true">
                        <template v-slot:default="slotProps">
                            {{ slotProps.item }}
                            <span>({{ itemsCount > 1 ? `${itemsCount} Items` : `${itemsCount} Item` }})</span>
                        </template>
                        <template v-slot:display="slotProps">
                            {{ slotProps.item }}
                        </template>
                    </app-select>
                </form-control>
            </div>
            <!-- FILTER BY END -->

            <!-- RECENTLY LAUNCHED PRODUCTS LIST START -->
            <div v-if="filterInput == 'New Launched'">
                <div class="card card-style mb-3 mt-3 mx-0"
                    v-if="recentlyLaunchedProducts && recentlyLaunchedProducts.length > 0">
                    <div class="content mb-1">
                        <div v-for="(product,index) in recentlyLaunchedProducts" :key="product.id">
                            <div class="border-top mb-2" v-if="index != 0"></div>
                            <div class="ml-3">
                                <h5 class="letterSpacing font-15 text-capitalize">
                                    {{product.Product_Name}}
                                </h5>
                                <div class="mt-1">
                                    <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units ==1">
                                        ({{product.crm_data.Box_Units}} unit)
                                    </span>
                                    <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                        v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                                        ({{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}})
                                    </span>
                                </div>
                                <div class="d-flex mt-3 mb-2 justify-content-between align-items-center">
                                    <h1 class="m-0 p-0 font-18 text-success">
                                        {{product.crm_data.$currency_symbol}}
                                        {{product.crm_data.Base_Price*(product.crm_data.Box_Units || 1)}}
                                    </h1>
                                    <div>
                                        <div class="d-flex" v-if="product.crm_data.Qty_in_Stock > 0">
                                            <small class="p-2 font-600 letterSpacing text-secondary">
                                                QTY.
                                            </small>
                                            <div class="rounded-s d-flex justify-content-center align-items-center">
                                                <a href="javascript:void(0);" @click="setQuantity(product, -1)">
                                                    <div class="minusIcon">
                                                        <i class="fa fa-minus font-10"
                                                            :class="[product.quantity > 0 ? 'primary-alt-base-text' : 'text-secondary']"></i>
                                                    </div>
                                                </a>
                                                <input :id="'quantity_'+product.id"
                                                    class="fw-bolder border-bottom border-top-0 border-start-0 border-end-0 text-center border-secondary"
                                                    type="number" min="0" :max="product.available_quantity"
                                                    v-model="product.quantity">
                                                <a href="javascript:void(0);" @click="setQuantity(product, 1)">
                                                    <div class="plusIcon">
                                                        <i class="fa fa-plus font-10 primary-alt-base-text"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="text-end text-danger letterSpacing font-600" v-else>
                                            Out of stock
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center" v-if="!(recentlyLaunchedProducts.length > 0)">
                    <i class="fas fa-search font-20 text-secondary mb-3"></i>
                    <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                        No New launched product Found
                    </p>
                </div>
            </div>
            <!-- RECENTLY LAUNCHED PRODUCTS LIST END -->



            <!-- ALL PRODUCTS LIST START -->
            <div class="card card-style mb-3 mt-3 mx-0" v-for="group in groups" :key="group.key"
                v-if="filterInput == 'All Products'">
                <div class="content mb-1">
                    <div v-for="(product,index) in group.value" :key="product.id">
                        <div class="border-top mb-2" v-if="index != 0"></div>
                        <div class="ml-3">
                            <h5 class="letterSpacing font-15 text-capitalize">
                                {{product.Product_Name}}
                            </h5>
                            <div class="d-flex justify-content-between">
                                <p class="primary-light-text px-2 mb-0 font-12 font-500 rounded-l productMarginBadge">
                                    {{ product.margin_percent ? `${product.margin_percent}%` : '' }}
                                </p>
                                <p
                                    :class="[product.crm_data.Recently_Launched ? 'primary-alt-light-bg text-white mb-0 font-12 font-500 newLaunchedProductBadge' : 'd-none']">
                                    {{ product.crm_data.Recently_Launched ? 'New' : '' }}
                                </p>
                            </div>
                            <div class="mt-1">
                                <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                    v-if="product.crm_data.Box_Units && product.crm_data.Box_Units ==1">
                                    ({{product.crm_data.Box_Units}} unit)
                                </span>
                                <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                    v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                                    ({{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}})
                                </span>
                            </div>
                            <div class="d-flex mt-0 mb-2 justify-content-between align-items-center">
                                <h1 class="m-0 p-0 font-18 text-success">
                                    {{product.crm_data.$currency_symbol}}
                                    {{product.crm_data.Base_Price*(product.crm_data.Box_Units || 1)}}
                                </h1>
                                <div>
                                    <div class="d-flex" v-if="product.crm_data.Qty_in_Stock > 0">
                                        <small class="p-2 font-600 letterSpacing text-secondary">
                                            QTY.
                                        </small>
                                        <div class="rounded-s d-flex justify-content-center align-items-center">
                                            <a href="javascript:void(0);" @click="setQuantity(product, -1)">
                                                <div class="minusIcon">
                                                    <i class="fa fa-minus font-10"
                                                        :class="[product.quantity > 0 ? 'primary-alt-base-text' : 'text-secondary']"></i>
                                                </div>
                                            </a>
                                            <input :id="'quantity_'+product.id"
                                                class="fw-bolder border-bottom border-top-0 border-start-0 border-end-0 text-center border-secondary"
                                                type="number" min="0" :max="product.available_quantity"
                                                v-model="product.quantity">
                                            <a href="javascript:void(0);" @click="setQuantity(product, 1)">
                                                <div class="plusIcon">
                                                    <i class="fa fa-plus font-10 primary-alt-base-text"></i>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="text-end text-danger letterSpacing font-600" v-else>
                                        Out of stock
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(products.length > 0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Product Found
                </p>
            </div>
            <!-- ALL PRODUCTS LIST END -->
        </div>
        <!-- AVAILABLE PRODUCTS LIST END -->



        <!-- COMING SOON PRODUCTS LIST START -->
        <div data-bs-parent="#tab-group-1" class="collapse" id="tab-2ab">
            <div class="card card-style mb-3 mt-3 mx-0" v-for="group in comingGroups" :key="group.key">
                <div class="content mb-1">
                    <div v-for="(product,index) in group.value" :key="product.id">
                        <div class="border-top mb-2" v-if="index != 0"></div>
                        <div class="ml-3">
                            <h5 class="letterSpacing font-15 text-capitalize">
                                {{product.Product_Name}}
                            </h5>
                            <span class="fw-bold primary-alt-light-text"
                                v-if="product.crm_data.Box_Units && product.crm_data.Box_Units ==1">
                                x {{product.crm_data.Box_Units}} unit
                            </span>
                            <span class="primary-alt-light-text fw-bold"
                                v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                                x {{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}}
                            </span>

                            <!-- <div class="d-flex mt-3 mb-2 justify-content-between align-items-center">
                                <h1 class="m-0 p-0 ">{{product.crm_data.$currency_symbol}}
                                    {{product.crm_data.Base_Price}}</h1>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-if="!(comingGroups.length>0)">
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Updates Here...
                </p>
            </div>
        </div>
        <!-- COMING SOON PRODUCTS LIST END -->


    </div>
</div>

<!-- PREPARE ORDER PAGE SECTION END-->


<!-- REVIEW PAGE SECTION START-->

<div class="page-content reviewPageContainer" v-if="mode == 'review'">

    <!-- REWARD INFORMATION START -->

    <div v-if="rewardPercentage"
        class="card card-style mb-5 primary-light-bg d-flex justify-content-center align-items-center"
        style="box-shadow: 0px 10px 5px rgb(133, 132, 132); max-height: 150px;min-height: 150px;height: 150px;">
        <div class="content primary-alt-light-text text-white text-center letterSpacing font-17 font-200">
            You will receive
            <div class="my-2">
                <div class="d-flex justify-content-center my-2" style="min-height: 27px;">
                    <div v-if="rewardEarned > 0">
                        <i class="fas fa-coins primary-alt-light-text font-18 me-2"></i>
                        <span class="font-25 font-600 primary-alt-light-text">
                            {{ rewardEarned }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center " v-else>
                        <div class="spinner-border primary-alt-light-text"
                            style="border-width: 2px; width: 1.6rem; height: 1.6rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

                <h4 class="font-500 ms-2 mb-0 letterSpacing text-center text-white">
                    Lifely Coins
                </h4>
            </div>
            upon completion of this order.
        </div>

    </div>

    <!-- REWARD INFORMATION END -->

    <!-- ITEMS SECTION START-->

    <div class="reviewPageAllCardCommonContainer ms-4">
        <div class="text-center">
            <i class="fas fa-shopping-bag cartIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Items
            </h4>
        </div>
    </div>

    <div v-if="orderedProducts && orderedProducts.length > 0">
        <div class="card card-style mb-3 mt-3" v-for="product in orderedProducts" :key="product.id">
            <div class="content mb-0">
                <div>
                    <div class="ml-3">
                        <h5 class="letterSpacing font-15 text-capitalize">
                            {{product.Product_Name}}
                        </h5>
                        <p class="primary-light-text px-2 mb-0 font-12 font-500 rounded-l productMarginBadge">
                            {{ product.margin_percent ? `${product.margin_percent}%` : '' }}
                        </p>
                        <div class="mt-1">
                            <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                v-if="product.crm_data.Box_Units && product.crm_data.Box_Units ==1">
                                ({{product.crm_data.Box_Units}} unit)
                            </span>
                            <span class="font-11 font-500 letterSpacing primary-alt-light-text"
                                v-if="product.crm_data.Box_Units && product.crm_data.Box_Units > 1">
                                ({{product.crm_data.Box_Units}} units in a {{product.crm_data.Usage_Unit}})
                            </span>
                        </div>
                        <div class="d-flex mt-0 mb-2 justify-content-between">
                            <h1 class="m-0 p-0 font-18 text-success">
                                {{product.crm_data.$currency_symbol}}
                                {{product.crm_data.Base_Price*(product.crm_data.Box_Units || 1)}}
                            </h1>
                            <div>
                                <div class="d-flex" v-if="product.crm_data.Qty_in_Stock > 0">
                                    <small class="p-2 font-600 letterSpacing text-secondary">
                                        QTY.
                                    </small>
                                    <div class="rounded-s d-flex justify-content-center align-items-center">
                                        <a href="javascript:void(0);" @click="setQuantity(product, -1)">
                                            <div class="minusIcon">
                                                <i class="fa fa-minus font-10"
                                                    :class="[product.quantity > 0 ? 'primary-alt-base-text' : 'text-secondary']"></i>
                                            </div>
                                        </a>
                                        <input :id="'quantity_'+product.id"
                                            class="fw-bolder border-bottom border-top-0 border-start-0 border-end-0 text-center border-secondary qtyItem"
                                            type="number" min="1" max="1000" v-model="product.quantity">
                                        <a href="javascript:void(0);" @click="setQuantity(product, 1)">
                                            <div class="plusIcon">
                                                <i class="fa fa-plus font-10 primary-alt-base-text"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ITEMS SECTION END-->


    <div class="divider-icon divider-margins bg-gray-dark mb-5 mt-5">
        <i class="fa font-20 color-gray-dark fa-cart-plus"></i>
    </div>






    <!-- SHIPPING SECTION START-->

    <div class="reviewPageAllCardCommonContainer ms-4 mt-5">
        <div class="text-center">
            <i class="fas fa-shipping-fast shippingIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Shipping Address
            </h4>
        </div>
    </div>

    <div class="card card-style mt-3">
        <div class="content">
            <h5 class="text-capitalize mb-3 letterSpacing">
                <i class="fas fa-building businessIcon"></i>
                {{profile.business_name}}
            </h5>
            <div class="col-12 letterSpacing fs-6" v-if="profile.address || profile.city">
                {{profile.address}},{{profile.city}}
            </div>
            <div class="col-12 letterSpacing fs-6" v-if="profile.state || profile.zipcode || profile.country">
                {{profile.state}}-{{profile.zipcode}}
                <span class="letterSpacing">
                    ( {{ profile.country_emoji ? profile.country_emoji : "" }}
                    {{ profile.country ? profile.country : "" }} )
                </span>
            </div>
        </div>
    </div>

    <!-- SHIPPING SECTION END-->


    <!-- CONTACT SECTION START-->

    <div class="reviewPageAllCardCommonContainer ms-4 mt-5">
        <div class="text-center">
            <i class="fas fa-address-book contactIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Contact Detail
            </h4>
        </div>
    </div>

    <div class="card card-style py-3 px-2 mt-3">
        <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
            style="opacity: 1;background-color: transparent;">
            <i class="fas fa-envelope-open text-secondary"></i>
            Email
        </label>
        <div type="text" class="letterSpacing py-2 form-control fs-6 font-600 mb-3 text-secondary"
            style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
            {{ profile.email ? profile.email : '-' }}
        </div>

        <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
            style="opacity: 1;background-color: transparent;">
            <i class="fas fa-phone text-secondary"></i>
            Phoneno
        </label>
        <div type="text" class="letterSpacing py-2 form-control fs-6 font-600 mb-3 text-secondary"
            style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
            {{ profile.phone ? profile.phone : '-' }}
        </div>
    </div>


    <!-- CONTACT SECTION START-->



    <!-- BILLING SECTION START-->

    <div class="reviewPageAllCardCommonContainer ms-4 mt-5" id="billingSummary">
        <div class="text-center">
            <i class="fas fa-receipt billingIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Billing Information
            </h4>
        </div>
    </div>



    <div class="card card-style mt-3">
        <div class="content">
            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">Sub Total</h5>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing billingAllNumber font-400 text-success">
                        {{orderedProducts[0].crm_data.$currency_symbol}}{{subTotal.toFixed(2)}}
                    </h4>
                </div>
            </div>
            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">Margin Amount</h5>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing text-danger font-400">
                        -{{orderedProducts[0].crm_data.$currency_symbol}}{{margin.toFixed(2)}}
                    </h4>
                </div>
            </div>
            <div class="d-flex mb-3 justify-content-between" v-if="rewards > 0">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">
                        <span class="primary-alt-light-text font-600">
                            <i class="fas fa-coins primary-alt-light-text font-17"></i>
                            {{ availableRewards }}
                        </span>
                        Lifely Coins
                    </h5>

                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing text-danger font-400">
                        -{{orderedProducts[0].crm_data.$currency_symbol}}{{availableRewardsAmount.toFixed(2)}}
                    </h4>
                </div>
            </div>
            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">Tax</h5>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing text-success font-400">
                        +{{orderedProducts[0].crm_data.$currency_symbol}}{{tax.toFixed(2)}}</h4>
                </div>
            </div>
            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">Shipping</h5>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing text-secondary font-400" v-if="shipping == 0">
                        +{{orderedProducts[0].crm_data.$currency_symbol}}{{shipping.toFixed(2)}}
                    </h4>
                    <h4 class="letterSpacing text-success font-400" v-if="shipping > 0">
                        +{{orderedProducts[0].crm_data.$currency_symbol}}{{shipping.toFixed(2)}}
                    </h4>
                </div>
            </div>
            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h5 class="font-600 letterSpacing">Adjustment</h5>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing font-400 text-danger" v-if="adjustment<0">
                        -{{orderedProducts[0].crm_data.$currency_symbol}}{{adjustment.toFixed(2)*-1}}
                    </h4>
                    <h4 class="letterSpacing font-400 text-success" v-if="adjustment>0">
                        +{{orderedProducts[0].crm_data.$currency_symbol}}{{adjustment.toFixed(2)}}
                    </h4>
                    <h4 class="letterSpacing font-400 text-secondary" v-if="adjustment==0">
                        {{orderedProducts[0].crm_data.$currency_symbol}}{{adjustment.toFixed(2)}}
                    </h4>
                </div>
            </div>

            <div class="divider"></div>

            <div class="d-flex mb-3 justify-content-between">
                <div class="mr-3">
                    <h4 class="letterSpacing primary-light-text">Total Amount</h4>
                </div>
                <div class="ml-auto">
                    <h4 class="letterSpacing text-success fontWeight">
                        {{orderedProducts[0].crm_data.$currency_symbol}}{{total.toFixed(2)}}
                    </h4>
                </div>
            </div>
        </div>
    </div>

    <!-- BILLING SECTION END-->

</div>

<!-- REVIEW PAGE SECTION END-->