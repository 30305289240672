<app-header menu="no">
    <router-link :to="{name: 'order-home'}" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </router-link>
    <h1 v-if="order" class="header-title text-capitalize" style="letter-spacing:0.5px;">Order#{{ order.title }}</h1>
    <a :href="order.invoice_url" target="_blank" class="header-icon header-icon-3"
        v-if="order && order.invoice_url != '' && order.invoice_url != null ">
        <i class="fas fa-download scale-box text-secondary font-16"></i>
    </a>
    <!-- <router-link :to="{name: 'notification-home'}" class="header-icon header-icon-2">
        <i class="fas fa-comments font-16 text-secondary position-relative"></i>
        <div class="chatMessageContainer">
            <span class="chatMessage">
                1
            </span>
        </div>
    </router-link> -->

</app-header>
<div class="page-title-clear"></div>

<div class="page-content" v-if="order">

    <!-- ORDER STATUSBAR START -->

    <div class="card card-style pt-2 mb-0 mt-3" v-if="!hasOrderStatusDelivered && !hasOrderStatusPaymentFailed">

        <div class="timeLineConnection1" :class="[ completeProcessingStatus ? 'lineCompleted':'']"></div>
        <div class="timeLineConnection2"></div>

        <div class="row my-2">

            <div class="orderStatusDeskSeperateWrapper col-4">

                <div class="orderStatusIconWrapper shadow-l mb-2" v-if="!completeProcessingStatus"
                    :class="[ !completeProcessingStatus ? 'activeIconWrapper': '' ]">
                    <i v-if="!completeProcessingStatus" class="fas fa-check orderStatusIcon"></i>
                </div>

                <div class="orderStatusIconWrapper shadow-l mb-2 completedStatus" v-if="completeProcessingStatus">
                    <i class="fas fa-hourglass-end orderStatusIcon"></i>
                </div>

                <h5 class="mb-0 lh-lg letterSpacing fontWeight" v-if="hasOrderStatusAwaiting"
                    :class="[hasOrderStatusAwaiting ? 'activeStatusTitle': '' ]">
                    Awaiting
                </h5>
                <h5 class="mb-0 lh-lg letterSpacing fontWeight" v-if="hasOrderStatusCreated"
                    :class="[hasOrderStatusCreated ? 'activeStatusTitle': '' ]">
                    Created
                </h5>
                <h5 class="mb-0 lh-lg letterSpacing fontWeight"
                    v-if="hasOrderStatusProcessing || completeProcessingStatus"
                    :class="[hasOrderStatusProcessing || completeProcessingStatus ? 'activeStatusTitle': '' ]">
                    Processing
                </h5>
            </div>


            <div class="orderStatusDeskSeperateWrapper col-4">
                <div class="orderStatusIconWrapper shadow-l mb-2"
                    :class="[hasOrderStatusShipped ? 'activeIconWrapper': '' ]">
                    <i class="fas fa-shipping-fast orderStatusIcon" v-if="!hasOrderStatusShipped"></i>
                    <i class="fas fa-check orderStatusIcon" v-if="hasOrderStatusShipped"></i>
                </div>
                <h5 class="mb-0 lh-lg letterSpacing fontWeight"
                    :class="[hasOrderStatusShipped ? 'activeStatusTitle': '' ]">
                    Shipped
                </h5>
            </div>


            <div class="orderStatusDeskSeperateWrapper col-4">
                <div class="orderStatusIconWrapper shadow-l mb-2">
                    <i class="fas fa-flag orderStatusIcon"></i>
                </div>
                <h5 class="mb-0 lh-lg letterSpacing fontWeight">
                    Delivered
                </h5>
            </div>
        </div>
    </div>

    <!-- ORDER STATUSBAR END -->

    <!-- ORDER DELIVERED STATUS START -->

    <div class="pb-0" v-if="hasOrderStatusDelivered">
        <div class="rounded-m">
            <h1 class="text-center my-3 pb-0">
                <i class="fa fa-3x fa-check-circle successIcon"></i>
            </h1>
            <h1 class="text-center text-capitalize font-600 letterSpacing successTitle">
                Delivered
            </h1>
        </div>
    </div>

    <!-- ORDER DELIVERED STATUS END -->


    <!-- PAYMENT FAILED STATUS START -->

    <div class="pb-0 mx-2" v-if="hasOrderStatusPaymentFailed">
        <div class="rounded-m">
            <h1 class="text-center my-3 pb-0">
                <i class="fa fa-3x fa-times-circle color-red-dark"></i>
            </h1>
            <h1 class="text-center text-capitalize font-600 letterSpacing color-red-dark">
                Payment Failed
            </h1>
        </div>
    </div>

    <!-- PAYMENT FAILED STATUS END -->


    <!-- EARNED LIFELY COINS START -->

    <div class="page-title-clear" v-if="!hasOrderStatusPaymentFailed && order && order.reward_earned > 0"></div>

    <div class="rewardSuceesImageContainer" v-if="!hasOrderStatusPaymentFailed && order && order.reward_earned > 0">
        <img src="https://shop-lifely-world-live.s3.ap-south-1.amazonaws.com/media/partner-app-images/promo-giftbox.png"
            class="img-fluid rewardImage" width="400">
    </div>
    <div class="card card-style mt-3 pt-5 primary-light-bg"
        v-if="!hasOrderStatusPaymentFailed && order && order.reward_earned > 0">
        <div v-if="$store.state.user.user_type != 'super_admin' && hasOrderStatusDelivered"
            class="lh-lg mb-0 mx-2 py-2 text-center">
            <p class="font-30 font-600 mb-0 primary-alt-light-text mx-1">
                <i class="fa fa-coins font-25"></i>
                {{ order.reward_earned }}
            </p>
            <p class="font-16 font-300 letterSpacing mb-0 text-white">
                You Earned Lifely Coins
            </p>
        </div>

        <div v-if="!hasOrderStatusDelivered" class="lh-lg mb-0 mx-2 py-2 text-center">
            <p class="font-30 font-600 mb-0 primary-alt-light-text mx-1">
                <i class="fa fa-coins font-25"></i>
                {{ order.reward_earned }}
            </p>
            <p class="font-16 font-300 letterSpacing mb-0 text-white">
                Lifely Coins Will Be Credited After Delivery
            </p>
        </div>

        <h3 v-if="$store.state.user.user_type == 'super_admin'  && hasOrderStatusDelivered"
            class="font-16 font-300 letterSpacing lh-lg mb-0 d-flex align-items-center justify-content-center text-white">
            <span class="font-30 me-1">
                🥳
            </span>
            Earned
            <span class="font-18 font-600 primary-alt-light-text mx-1">
                <i class="fas fa-coins"></i>
                {{ order.reward_earned }}
            </span>
            Lifely Coins
        </h3>
    </div>

    <!-- EARNED LIFELY COINS END -->


    <!-- ORDER SUMMARY START-->
    <div class="detailCardTitleContainer card ms-4 mt-5 mb-0" style="background-color: transparent;box-shadow: none;">
        <div class="text-center">
            <i class="fas fa-file-archive orderSummaryIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Order Summary
            </h4>
        </div>
    </div>


    <div class="card card-style mx-3 bg-theme pb-0 mt-3">
        <div class="content" id="tab-group-1">

            <div v-if="order.title">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    Order ID
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3  text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ order.title }}
                </div>
            </div>

            <div v-if="order.date">
                <label class="text-dark letterSpacing font-13 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    <i class="fas fa-clock text-secondary"></i>
                    Created On
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{ localDateTime(order.date)}}
                </div>
            </div>

            <div v-if="order.crm_data && order.crm_data.Shipped_On">
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    <i class="fas fa-clock text-secondary"></i>
                    Shipped On
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{localDateTime(order.crm_data.Shipped_On)}}
                </div>
            </div>

            <div v-if="order.crm_data && order.crm_data.Delivered_On">
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    <i class="fas fa-clock text-secondary"></i>
                    Delivered On
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{localDateTime(order.crm_data.Delivered_On)}}
                </div>
            </div>
        </div>
    </div>

    <!-- ORDER SUMMARY END-->


    <!-- SHIPPING SECTION START-->
    <div class="detailCardTitleContainer card shadow-none bg-transparent mb-0 ms-4 mt-5" v-if="order.crm_data">
        <div class="text-center">
            <i class="fas fa-shipping-fast shippingIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle mb-0">
                Shipping Address
            </h4>
        </div>
        <div class="partnerReference content" v-if="$store.state.user.user_type == 'super_admin'">
            <router-link class="header-icon header-icon-2"
                :to="{'name': 'partner-detail', params: {'id' : order.user_id}}">
                <i class="fas fa-user-circle primary-alt-light-text position-relative userIcon scale-box"></i>
            </router-link>
        </div>
    </div>

    <div class="card card-style mx-3 mt-3" v-if="order.crm_data">
        <div class="content">
            <h5 class="text-capitalize mb-3 letterSpacing">
                <i class="fas fa-building businessIcon"></i>
                {{order.crm_data.Shipping_Name}}
            </h5>
            <div class="col-12 letterSpacing fs-6">
                {{ order.crm_data.Shipping_Street}},{{ order.crm_data.Shipping_City}},&nbsp;
                <span class="letterSpacing">
                    {{order.crm_data.Shipping_State}},{{ order.crm_data.Shipping_Country}}-{{
                    order.crm_data.Shipping_Zipcode}}
                </span>
            </div>
        </div>
    </div>

    <!-- SHIPPING SECTION END-->

    <!-- ORDER ITEMS START-->

    <div class="detailCardTitleContainer ms-4 mt-5" v-if="order && order.items && order.items.length > 0">
        <div class="text-center">
            <i class="fas fa-box-open orderItemIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Order Items
            </h4>
        </div>
    </div>

    <div class="card" style="background-color: transparent;box-shadow: none;"
        v-if="order && order.items && order.items.length > 0">
        <div class="content mb-2">
            <table class="table table-borderless text-center shadow-l" style="overflow: hidden;">
                <thead>
                    <tr class="bg-gray1-dark">
                        <th scope="col" class="color-white letterSpacing tableHeadData"
                            style="background-color: rgb(170, 170, 170);">Item</th>
                        <th scope="col" class="color-white letterSpacing tableHeadData"
                            style="background-color: rgb(170, 170, 170);">Price</th>
                        <th scope="col" class="color-white letterSpacing tableHeadData"
                            style="background-color: rgb(170, 170, 170);">Qty</th>
                        <th scope="col" class="color-white letterSpacing tableHeadData"
                            style="background-color: rgb(170, 170, 170);">Total</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr v-for="item in order.items">
                        <td scope="row" class="letterSpacing tableRowData text-secondary">{{item.name}}</td>
                        <td class="letterSpacing tableRowData text-secondary">
                            {{order.country.currency_symbol}}{{item.base_price}}</td>
                        <td class="letterSpacing tableRowData text-secondary">{{item.quantity}}</td>
                        <td class="letterSpacing tableRowData text-success">
                            {{order.country.currency_symbol}}{{item.sub_total}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- ORDER ITEMS END-->


    <!-- BILLING INFORMATION START-->

    <div class="detailCardTitleContainer card shadow-none bg-transparent mb-0 ms-4 mt-5">
        <div class="text-center">
            <i class="fas fa-receipt billingIcon"></i>
        </div>
        <div class="">
            <h4 class="letterSpacing order-cardTitle">
                Billing Information
            </h4>
        </div>
        <div class="downloadInvoice">
            <a :href="order.invoice_url" target="_blank" class="header-icon header-icon-2"
                v-if="order && order.invoice_url != '' && order.invoice_url != null ">
                <i class="fas fa-download scale-box primary-light-text font-19"></i>
            </a>
        </div>
    </div>
    <div class="card card-style mx-3 mt-3">
        <div class="content" id="tab-group-1">
            <div v-if="order.payment_id">
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    Transaction ID
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    {{order.payment_id}}
                </div>
            </div>

            <div v-if="order.paid_at">
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;" v-if="order.paid_at">
                    <i class="fas fa-clock text-secondary"></i>
                    Payment date
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);"
                    v-if="order.paid_at">
                    {{ localDateTime(order.paid_at)}}
                </div>
            </div>

            <div>
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    Payment Status
                </label>
                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    v-if="hasOrderStatusAwaiting"
                    style="border:none; border-radius: 30px !important;background-color: rgb(240, 240, 240);">
                    Waiting for confirmation
                </div>

                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    v-if="!hasOrderStatusAwaiting && !hasOrderStatusPaymentFailed"
                    style="border:none; border-radius: 30px !important;background-color: rgb(189, 247, 212);">
                    Completed
                </div>

                <div class="letterSpacing py-2 form-control fs-6 font-500 mb-3 text-secondary"
                    v-if="hasOrderStatusPaymentFailed"
                    style="border:none; border-radius: 30px !important;background-color: rgb(252, 220, 220);">
                    Payment Failed
                </div>
            </div>

            <div v-if="order && order.invoice_url != '' && order.invoice_url != null">
                <label class="text-dark letterSpacing font-14 font-600 ms-2 mb-2"
                    style="opacity: 1;background-color: transparent;">
                    Invoice
                </label>
                <a :href="order.invoice_url" target="_blank"
                    class="letterSpacing py-2 color-blue-dark form-control fs-6 font-500 mb-1"
                    style="border:none; border-radius: 30px !important;background-color: rgb(213, 229, 254);">
                    <i class="fas fa-download letterSpacing"></i>&nbsp;Download
                </a>
            </div>

            <div v-if="order && order.status != 'Awaiting Payment' && order.status != 'Payment Failed' ">
                <div class="divider-icon divider-margins bg-gray-dark mb-5 mt-5">
                    <i class="fa fa-coins mt-n3 font-30 bg-white coinIcon"></i>
                </div>

                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Sub Total</h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing text-success font-400">
                            {{order.country.currency_symbol}}{{order.sub_total}}
                        </h4>
                    </div>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Margin Amount</h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing text-danger font-400">
                            -{{order.country.currency_symbol}}{{order.margin}}
                        </h4>
                    </div>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">
                            <span class="primary-alt-light-text font-600">
                                <i class="fas fa-coins primary-alt-light-text font-17"></i>
                                {{ order.reward_redeemed }}
                            </span>
                            Lifely Coins
                        </h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing font-400"
                            :class="[order.reward_redeemed_amount > 0 ? 'text-danger' : 'text-secondary']">
                            {{ order.reward_redeemed_amount > 0 ? '-' : '' }}
                            {{order.country.currency_symbol}}{{order.reward_redeemed_amount}}
                        </h4>
                    </div>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Tax</h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing text-success font-400">
                            +{{order.country.currency_symbol}}{{order.tax}}
                        </h4>
                    </div>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Shipping</h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing text-secondary font-400" v-if="order.shipping == 0">
                            {{order.country.currency_symbol}}{{order.shipping}}
                        </h4>
                        <h4 class="letterSpacing text-success font-400" v-if="order.shipping > 0">
                            +{{order.country.currency_symbol}}{{order.shipping}}
                        </h4>
                    </div>
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Adjustment</h5>
                    </div>
                    <div class="ml-auto">

                        <h4 class="letterSpacing text-danger font-400" v-if="order.adjustment < 0">
                            -{{order.country.currency_symbol}}{{order.adjustment*-1}}
                        </h4>
                        <h4 class="letterSpacing text-success font-400" v-if="order.adjustment > 0">
                            +{{order.country.currency_symbol}}{{order.adjustment}}
                        </h4>
                        <h4 class="letterSpacing text-secondary font-400" v-if="order.adjustment == 0">
                            {{order.country.currency_symbol}}{{order.adjustment}}
                        </h4>

                    </div>
                </div>
                <div class="divider-icon divider-margins bg-gray-dark mb-4">
                </div>
                <div class="d-flex mb-3 justify-content-between">
                    <div class="mr-3">
                        <h5 class="font-600 letterSpacing">Paid Amount</h5>
                    </div>
                    <div class="ml-auto">
                        <h4 class="letterSpacing text-success" v-if="order.paid_amount > 0">
                            {{order.country.currency_symbol}}{{order.paid_amount}}
                        </h4>
                        <h4 class="letterSpacing text-secondary" v-if="order.paid_amount == 0">
                            {{order.country.currency_symbol}}{{order.paid_amount}}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- BILLING INFORMATION END-->

</div>
<app-footer />