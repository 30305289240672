/* eslint-disable no-undef */
import orderService from "../../services";


export default {
    data() {
        return {
            order: null,
            hasOrderStatusAwaiting: false,
            hasOrderStatusCreated: false,
            hasOrderStatusProcessing: false,
            hasOrderStatusShipped: false,
            hasOrderStatusDelivered: false,
            hasOrderStatusPaymentFailed: false,
            completeProcessingStatus: false
        }
    },
    mounted() {
        this.showLoader(true);
        orderService.getOrder(this.$route.params.id).then(response => {
            this.hideLoader();
            this.order = response.data;

            if (this.order.status == 'Awaiting Payment') {
                this.hasOrderStatusAwaiting = true;
            }
            if (this.order.status == 'Created') {
                this.hasOrderStatusCreated = true;
            }
            if (this.order.status == 'Processing') {
                this.hasOrderStatusProcessing = true;
            }
            if (this.order.status == 'Shipped') {
                this.completeProcessingStatus = true;
                this.hasOrderStatusShipped = true;
            }
            if (this.order.status == 'Delivered') {
                this.completeProcessingStatus = true;
                this.hasOrderStatusDelivered = true;
            }
            if (this.order.status == 'Payment Failed') {
                this.hasOrderStatusPaymentFailed = true;
            }
        });

        

    }
}