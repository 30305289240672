<div class="page-content">
    <div class="card successCardContainer">
        <div class="content mt-5">
            <div class="shadowAnimation"></div>
            <h1 class="text-center mt-4 successIconContainer">
                <i class="fa fa-2x fa-check rounded-circle primary-alt-light-text successIcon">
                    <span class="glowAnimation"></span>
                </i>
            </h1>
        </div>
        <div class="content mt-5">
            <p class="text-center mt-3 mb-3 text-capitalize font-22 font-500 letterSpacing color-white">
                Order Created
            </p>
            <p class="font-16 font-500 primary-alt-light-text text-center letterSpacing" v-if="orderTitle">
                orderId: {{ orderTitle }}
            </p>
        </div>
    </div>

    <router-link :to="{'name': 'order-detail', params: {'id' : orderId}}"
        class="btn-center-l rounded-l text-capitalize font-18 font-500 letterSpacing orderActionBtn">
        Check Status
    </router-link>
</div>